import isNullOrEmpty from "./isNullOrEmpty";

export default function keyValueStateToObject(state) {
  if (state === undefined || state === null) {
    console.error("[" + keyValueStateToObject.name + "]: State is ", state);
    return {};
  }

  const obj = {};
  state.map(row => {
    if (!isNullOrEmpty(row.key)) {
      obj[row.key] = row.value;
    }
    return null;
  });
  return obj;
}