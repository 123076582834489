// @flow strict-local

import { useParams } from "react-router-dom";
import VATROCWhoCard from "./VATROCWhoCard.react";

import * as React from "react";

export default function VATROCWho({ setToolName }): React.MixedElement {
  setToolName("Who");
  const { uid } = useParams();
  return <VATROCWhoCard uid={uid} />;
}
