// @flow strict-local

import {CurrentUserContext} from "../utils/currentUserContext";
import {LOCAL_STORAGE_USER_TOKEN} from "./userUtils";
import {useContext} from "react";
import FunctionButton from "../../components/buttons/FunctionButton.react";

export default function VATROCLogoutButton({
}): React.MixedElement {
  const {setCurrentUser} =
    useContext(CurrentUserContext);

  return (
    <FunctionButton
      label="Logout"
      onClick={() => {
        localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN);
        setCurrentUser(null);
      }}
    />
  );
}
