// @flow strict-local

import * as React from "react";
import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setUserToken } from "../user/userUtils";
import { CurrentUserContext } from "../utils/currentUserContext";

export default function VATROCSingleSignOn({ next }): React.MixedElement {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { setCurrentUserToken } = useContext(CurrentUserContext);
  if (token) {
    setCurrentUserToken(token);
    setUserToken(token);
  }
  const url_next = searchParams.get("next");
  const navigate = useNavigate();

  useEffect(() => {
    console.log(url_next);
    if (url_next) {
      return navigate(url_next);
    } else if (next) {
      return navigate(next);
    }
    return navigate("/");
  });
  return <div>VATROC SSO</div>;
}
