// @flow strict-local

import {CurrentUserContext} from "./utils/currentUserContext";
import {ErrorBoundary} from "../components/ErrorBoundary.react";
import {Outlet} from "react-router-dom";
import {Suspense, useContext, useEffect} from "react";
import {useLoginUser} from "./user/useLoginUser";
import * as React from "react";
import VATROCLoggedOut from "./single_sign_on/VATROCLoggedOut.react";
import VATROCUserState from "./user/VATROCUserState.react";
import LinkButton from "../components/buttons/LinkButton.react";

const bannerHeight = "60px";

export default function VATROCLayout({toolName}): React.MixedElement {
  const {currentUser, setCurrentUser} = useContext(CurrentUserContext);

  const {userData, isLoading} = useLoginUser();
  useEffect(() => {
    setCurrentUser(userData);
  }, [userData, setCurrentUser]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (currentUser == null) {
    return <VATROCLoggedOut />;
  }

  return (
    <>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: bannerHeight,
          justifyContent: "space-between",
        }}
      >
        <div className="ms-3">
          <LinkButton
            style={{color: "black"}}
            label={"VATROC " + toolName}
            href="/"
            hideBorder
          />
        </div>
        <div className="me-3">
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Suspense fallback={<div>Loading...</div>}>
              <VATROCUserState />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
      <div
        style={{
          background:
            "linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)),url(https://www.vatroc.net/wp-content/uploads/2021/01/radar.jpg)",
        }}
        className="p-3"
      >
        <Outlet user={currentUser} />
      </div>
    </>
  );
}
