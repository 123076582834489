import SplitPane from "./SplitPane.react";
import CopyTextToClipboard from "../ClipboardTextLink.react";

export default function KeyValueListView({keyedObject, objectKeys = [], excludeList = []}) {
  return (
    Object.keys(keyedObject)
      .filter(key => !excludeList.includes(key))
      .map(key => {
        const value = keyedObject[key];
        return (
          <div>
            <SplitPane
              leftProportion={15}
              left={
                <CopyTextToClipboard text={key} />
              }
              right={
                objectKeys.includes(key) ?
                  <KeyValueListView keyedObject={value} />
                  : <CopyTextToClipboard text={value} />
              } />
          </div>
        );
      })
  );
}