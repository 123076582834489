// @flow strict-local

import {FaExternalLinkAlt} from "react-icons/fa";
import {useEffect, useState} from "react";
import * as React from "react";
import CancelButton from "../../components/buttons/CancelButton.react";
import CardHeader from "../../components/layouts/CardHeader.react";
import CardWrapper from "../../components/layouts/CardWrapper.react";
import LinkButton from "../../components/buttons/LinkButton.react";
import ListGroup from "../../components/lists/ListGroup.react";
import ListGroupItem from "../../components/lists/ListGroupItem.react";
import UserRoundProfile from "../../components/user/UserRoundProfile.react";
import VATROCFetch from "../utils/VATROCFetch";

function Sessions({user}) {
  return user.sessions && (
    <>
      <CardHeader left={<>Sessions</>} right={<LinkButton label={<FaExternalLinkAlt />} href="/atc_manager" hideBorder={true} target="_blank" />} />
      <ListGroup>
        {user.sessions.map((session) => {
          const session_time = Number.parseInt(session.minutes_on_callsign / 60);
          const end_time =  session.end;
          return (
            <ListGroupItem left={<>{session.callsign}</>} right={<>{session.start} {session_time}</>} />
          );
        })
        }
      </ListGroup></>
  );
}

function EventList({user}) {
  return user.event_list && (
    <>
      <CardHeader left={<>Event list</>} />
      <ListGroup>
        {[
          "DEL OJT",
          "DEL CPT",
          "GND OJT",
          "GND CPT",
          "TWR OJT",
          "TWR CPT",
          "APP OJT",
          "APP CPT",
          "CTR OJT",
          "CTR CPT",
        ].map((val) => (
          <ListGroupItem left={<>{val}</>} right={<>1</>} />
        ))}
      </ListGroup></>
  );
}

function Timeline({user}) {
  return user.timeline && (
    <>
      <CardHeader left={<>Timeline</>} />
      <ListGroup>
        {[
          "DEL OJT",
          "DEL CPT",
          "GND OJT",
          "GND CPT",
          "TWR OJT",
          "TWR CPT",
          "APP OJT",
          "APP CPT",
          "CTR OJT",
          "CTR CPT",
        ].map((val) => (
          <ListGroupItem
            left={<>{val}</>}
            right={
              <>
                {user.timeline[val].date} ({user.timeline[val].delta} days)
              </>
            }
          />
        ))}
      </ListGroup>
    </>
  );
}

export default function VATROCWhoCard({uid, onHide}): React.MixedElement {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    VATROCFetch(`who/${uid}`).then((data) => {
      setUser(data);
      setIsLoading(false);
    });
  }, [uid]);
  const ratingMap = {S1: "2", S2: "3", "S+": "4"};

  if (user == null) {
    return <></>;
  }
  if (isLoading) {
    return <CardWrapper className="h-100">Loading...</CardWrapper>;
  }
  console.log(user);
  return (
    <CardWrapper className="h-100">
      <CardHeader className="mb-1" right={<CancelButton label="Close" onClick={onHide} />} />
      <ListGroup>
        <ListGroupItem
          left={<UserRoundProfile src={user.user_profile_src} />}
          right={<>{user.user.data.display_name}</>}
        />
        <ListGroupItem left={<>Rating</>} right={<>{user.rating}</>} />
        <ListGroupItem left={<>Position</>} right={<>{user.position}</>} />
      </ListGroup>

      <CardHeader left={<>Hour Statistics</>} />
      {user.hours_at && (
        <ListGroup>
          {["S1", "S2", "S+"].map((val) => (
            <ListGroupItem
              left={<>{val}</>}
              right={
                Object.keys(user.hours_at).includes(ratingMap[val])
                  ? user.hours_at[ratingMap[val]]["total"] +
                  "(OJT: " +
                  user.hours_at[ratingMap[val]]["OJT"] + ")"
                  : "-"
              }
            />
          ))}
        </ListGroup>
      )}
      {/* <Timeline user={user} /> */}
      {/* <EventList user={user} /> */}
      <Sessions user={user} />
    </CardWrapper>
  );
}
