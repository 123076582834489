// @flow strict-local

import * as React from "react";
import BasicTable from "../../../components/table/BasicTable.react";
import UserRoundProfile from "../../../components/user/UserRoundProfile.react";

function TableCell({children}) {
  return <span style={{whiteSpace: "nowrap"}}>{children}</span>;
}

export default function VATROCRosterTable({list, isListLoading, activeEntry, onRowActivation}): React.MixedElement {

  const keys = [
    "uid",
    "display_name",
    "vatsim_uid",
    "vatroc_position",
    "vatsim_rating",
  ];
  const cellSpec = {
    uid: {
      header: <TableCell></TableCell>,
      cell: (item) => (
        <UserRoundProfile uid={item.uid} src={item.user_profile_src} />
      ),
      width: null,
    },
    display_name: {
      header: <TableCell>Name</TableCell>,
      cell: (item) => (
        <TableCell>{item.display_name}</TableCell>
      ),
      width: null,
    },
    vatsim_uid: {
      header: <TableCell>UID</TableCell>,
      cell: (item) => (
        <TableCell>{item.vatroc_vatsim_uid}</TableCell>
      ),
      width: null,
    },
    vatroc_position: {
      header: <TableCell>Position</TableCell>,
      cell: (item) => (
        <TableCell>{item.vatroc_position_text}</TableCell>
      ),
      width: null,
    },
    vatsim_rating: {
      header: <TableCell>Rating</TableCell>,
      cell: (item) => (
        <TableCell>{item.vatroc_vatsim_rating_text}</TableCell>
      ),
      width: null,
    },
  };

  return isListLoading ? <div>"Loading..."</div>
    : <BasicTable
      index={true}
      keys={keys}
      spec={cellSpec}
      data={list}
      onRowActivation={onRowActivation}
      active={activeEntry}
    />;
}
