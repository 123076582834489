import {useState} from "react";

export default function Tooltip({children, text, className}) {
  const [isHovered, setIsHovered] = useState(false);
  // const [mousePosition, setMousePosition] = useState({x: 0, y: 0});

  return (
    <div
      style={{position: "relative"}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={className}
    // onMouseMove={(event) => {setMousePosition({x: event.clientX, y: event.clientY});}}
    >
      {children}
      {isHovered && <div
        style={{
          // position: 'fixed',
          // top: mousePosition.y + 20,
          // left: mousePosition.x + 20,
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          color: '#fff',
          padding: '8px',
          borderRadius: '4px',
          fontSize: '14px',
          zIndex: '9999',
        }}
      >
        {text}
      </div>}
    </div>);
};