// @flow strict-local

import {FaChevronUp, FaChevronDown} from "react-icons/fa";
import {Table} from "react-bootstrap";
import {useState} from "react";
import * as React from "react";
// import useSortableTable from "../../vatroc/utils/useSortableTable";

export default function BasicTable({
  keys,
  spec,
  data,
  onRowActivation,
  active,
  index = false,
}): React.MixedElement {
  const [hover, setHover] = useState(null);
  const cursor = onRowActivation ? "pointer" : null;
  const _active = active == null ? [] : Array.isArray(active) ? active : [active];
  const [orderBy, setOrderBy] = useState(null);
  const [reverseOrder, setReverserOrder] = useState(false);
  // const {data: _data} = useSortableTable(data, reverseOrder, orderBy);
  const _data = data;

  return (
    <Table>
      <thead>
        <tr>
          {index && <th></th>}
          {keys &&
            keys.map(
              (key) => spec[key] && <th
                key={key}
                onClick={() => {
                  if (orderBy === key) {
                    if (reverseOrder) {
                      setOrderBy(null);
                      setReverserOrder(false);
                    } else {
                      setReverserOrder(true);
                    }
                  } else {
                    setReverserOrder(false);
                    setOrderBy(key);
                  }
                }}
              >{spec[key].header} {orderBy === key ? reverseOrder ? <FaChevronDown /> : <FaChevronUp /> : null}</th>
            )}
        </tr>
      </thead>
      <tbody style={{cursor}}>
        {_data &&
          _data.map((row, idx) => (
            row && <tr
              style={{
                overflowY: "hidden",
                background:
                  onRowActivation && (hover === row || _active.includes(idx))
                    ? "#6c757d"
                    : null,
                color:
                  onRowActivation && (hover === row || _active.includes(idx))
                    ? "#fff"
                    : null,
              }}
              onMouseEnter={(event) => {
                setHover(row);
              }}
              onMouseLeave={() => {
                setHover(null);
              }}
              onClick={() => onRowActivation(idx)}
            >
              {index && <td>{idx + 1}</td>}
              {keys.map((key) => spec[key] && <td>{spec[key].cell(row)}</td>)}
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
