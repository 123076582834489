import {Button} from "react-bootstrap";

export default function LinkButton({
  label = "Click me",
  href,
  className,
  size,
  target,
  style,
  hideBorder = false
}) {
  return <Button
    className={"ms-1 " + className}
    href={href}
    size={size}
    target={target}
    variant="outline-secondary"
    style={{border: hideBorder ? 0 : null, ...style}}
  >{label}</Button>;
}