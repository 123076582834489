// @flow strict-local

import * as React from "react";
import {Routes, Route} from "react-router-dom";
import MikeHome from "./MikeHome.react";

export default function MikeRouter(): React.MixedElement {
  if (process.env.REACT_APP_DOMAIN === "mikehome") {
    return <MikeHome />;
  }
  return (
    <Routes>
      <Route path="/">
        <Route index element={<MikeHome />} />
      </Route>
    </Routes>
  );
}