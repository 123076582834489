import FunctionButton from "../components/buttons/FunctionButton.react";
import CardWrapper from "../components/layouts/CardWrapper.react";
import postData from "../utils/fetch";

export default function SingleSignOn({ }) {
  return (
    <div
      className="p-3 w-100"
    >
      <CardWrapper title="Single Sign On">
        <FunctionButton
          label="Trigger Discord"
          onClick={async () => {
            const client_id = "1106328754596360323";
            const redirect_uri = "https%3A%2F%2Fwww.vatroc.net%2F";
            const response = postData(
              `https://discord.com/api/oauth2/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=identify`,
            );
            console.log(await response);
            console.log(`https://discord.com/api/oauth2/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=identify`);
          }}
        />
      </CardWrapper>
    </div>
  );
}