import {Card} from "react-bootstrap";

export default function CardWrapper({
  title,
  children,
  className,
  right,
  style
}) {
  return (
    <Card className={"ms-3 mb-3 me-3 p-3"} style={{overflow: "auto", ...style}}>
      {title && <Card.Title>{title}{right &&
        <span style={{float: "right"}}>{right}</span>}</Card.Title>}
      <Card.Body>{children}</Card.Body>
    </Card>
  );
}
