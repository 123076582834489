// @flow strict-local

import WrapPromise from "../../utils/WrapPromise";
import { getUserToken } from "../user/userUtils";

const URL = "https://www.vatroc.net/wp-json/vatroc/v1/";

export default async function fetchWorker(endpoint, metadata) {
  const token = getUserToken();
  if (token == null) {
    return null;
  }
  const headers = metadata && metadata.headers ? metadata.headers : {};
  headers["Authorization"] = "Bearer " + token;
  
  const response = await fetch(URL + endpoint, { ...metadata, headers });
  const text = await response.text();

  if (text.length === 0) {
    return true;
  }
  const json = JSON.parse(text);
  return json;
}

/**
 * Example:
 * const res = VATROCFetch("who_am_i").then((data) => setData(data));
 */
