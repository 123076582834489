import isNullOrEmpty from "./isNullOrEmpty";

export default function pushDefaultIfLastNonNull(stateObj, defaultValue) {
  if (stateObj.length === 0) {
    stateObj.push(defaultValue);
    return stateObj;
  }

  const tail = stateObj.pop();
  if (tail && (!isNullOrEmpty(tail.key) || !isNullOrEmpty(tail.value))) {
    stateObj.push(tail);
    stateObj.push(defaultValue);
  } else {
    stateObj.push(defaultValue);
  }
  return stateObj;
}