// @flow strict-local

import { useEffect, useState } from "react";
import VATROCFetch from "../utils/VATROCFetch";

export function useLoginUser() {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const queryUser = async () => {
    setIsLoading(true);
    const data = await VATROCFetch("who_am_i");
    setUserData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    queryUser();
  }, []);

  return {userData, refetchUser: queryUser, isLoading};
}
