// @flow strict-local

import * as React from "react";
import { useState } from "react";
import ConvertoGroupCard from "./ConvertoGroupCard.react";

export default function ConvertoGroupWeightCard({
  saveToLocal = false,
  storagePrefix = "CONVERTO",
}): React.MixedElement {
  const LOCAL_STORAGE_KG = storagePrefix + "_KG";
  const LOCAL_STORAGE_MG = storagePrefix + "_MG";
  const LOCAL_STORAGE_LB = storagePrefix + "_LB";
  const LOCAL_STORAGE_OZ = storagePrefix + "_OZ";
  const [kg, onChangeKg] = useState(localStorage.getItem(LOCAL_STORAGE_KG) || "");
  const [mg, onChangeMg] = useState(localStorage.getItem(LOCAL_STORAGE_MG) || "");
  const [lb, onChangeLb] = useState(localStorage.getItem(LOCAL_STORAGE_LB) || "");
  const [oz, onChangeOz] = useState(localStorage.getItem(LOCAL_STORAGE_OZ) || "");

  const setLocalStorage = (obj) => {
    const pairs = [
      [obj.kg, LOCAL_STORAGE_KG],
      [obj.mg, LOCAL_STORAGE_MG],
      [obj.lb, LOCAL_STORAGE_LB],
      [obj.oz, LOCAL_STORAGE_OZ],
    ];
    pairs.map(([val, key]) => {
      localStorage.setItem(key, val);
    });
  };

  const onChangeAny = (obj) => {
    const pairs = [
      [obj.kg, onChangeKg],
      [obj.mg, onChangeMg],
      [obj.lb, onChangeLb],
      [obj.oz, onChangeOz],
    ];
    let hasUndefined = false;
    pairs.map(([val, onChange]) => {
      if (val !== undefined) {
        onChange(val);
      } else {
        hasUndefined = true;
      }
    });

    if (hasUndefined) {
      pairs.map(([val, onChange]) => {
        onChange(0);
      });
    } else if (saveToLocal) {
      setLocalStorage(obj);
    }
  };

  const MULTIPLIER_KG_TO_LB = 2.2046226218;

  return (
    <ConvertoGroupCard
      title="Weight"
      units={[
        {
          label: "Kg",
          value: kg,
          onChange: (val) => {
            onChangeAny({
              kg: val,
              mg: val * 1000,
              lb: val * MULTIPLIER_KG_TO_LB,
              oz: (val * MULTIPLIER_KG_TO_LB) / 16,
            });
          },
        },
        {
          label: "Mg",
          value: mg,
          onChange: (val) => {
            onChangeAny({
              kg: val / 1000,
              mg: val,
              lb: (val / 1000) * MULTIPLIER_KG_TO_LB,
              oz: ((val / 1000) * MULTIPLIER_KG_TO_LB) / 16,
            });
          },
        },
        {
          label: "Lb",
          value: lb,
          onChange: (val) => {
            onChangeAny({
              kg: val / MULTIPLIER_KG_TO_LB,
              mg: (val * 1000) / MULTIPLIER_KG_TO_LB,
              lb: val,
              oz: val * 16,
            });
          },
        },
        {
          label: "Oz",
          value: oz,
          onChange: (val) => {
            onChangeAny({
              kg: val / MULTIPLIER_KG_TO_LB / 16,
              mg: (val * 1000) / MULTIPLIER_KG_TO_LB / 16,
              lb: val / 16,
              oz: val,
            });
          },
        },
      ]}
    />
  );
}
