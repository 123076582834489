// @flow strict-local

import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

export default function useSearchParamsIDToActiveEntry(list, paramKey) {
  const [searchParams] = useSearchParams();
  const [activeEntry, setActiveEntry] = useState(null);
  useEffect(() => {
    if (searchParams.get(paramKey) != null) {
      list != null && list.map((entry, idx) => {
        if (entry.uid === searchParams.get(paramKey)) {
          setActiveEntry(idx);
        }
        return null;
      });
    } else {
      setActiveEntry(null);
    }
  }, [list, searchParams, setActiveEntry, paramKey]);
  return {activeEntry};
}