// @flow strict-local

import * as React from "react";
import isNullOrEmpty from "../../utils/isNullOrEmpty";

export default function Flexbox({direction, ...props}): React.MixedElement {
    return (
        <div style={{
            ...props.style,
            display: "flex",
            flexDirection: !isNullOrEmpty(direction) ? direction : "column",
            justifyContent: "space-evenly"
        }}>
            {props.children}
        </div>
    );
}
