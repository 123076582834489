// @flow strict-local

import * as React from "react";

const who = "who/";

export default function UserRoundProfile({uid = null, src, tooltip, size = "50px"}) {
  const MaybeClickable = ({uid, children}) =>
    uid == null ? <>{children}</> : <a href={who + uid}>{children}</a>;

  return (
    <MaybeClickable uid={uid}>
      <img
        src={src}
        style={{
          borderRadius: size,
          height: size,
          width: size,
          display: "inline",
        }}
        title={tooltip}
        alt={tooltip}
      />
    </MaybeClickable>
  );
}
