// @flow strict-local

import * as React from "react";
import CardWrapper from "../components/layouts/CardWrapper.react";

export default function VATROC(): React.MixedElement {
  return (
    <CardWrapper>
      <a href="mission_control">Mission Control</a>
    </CardWrapper>
  );
}
