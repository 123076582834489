import copyToClipboard from "../utils/copyToClipboard";
import {useState} from "react";
import Tooltip from "./content/Tooltip.react";

export default function ClipboardTextLink({text, className}) {
  const _text = typeof text === 'string' ? text : JSON.stringify(text || "").replace(/"([^"]+)":/g, '$1:');
  const [isHovered, setIsHovered] = useState(false);
  return (<Tooltip text="Click to copy" className={className}>
    <p
      style={{cursor: "pointer", "textDecoration": isHovered ? "underline" : null}}
      onClick={() => {
        copyToClipboard(_text);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      [Copy]
    </p>
  </Tooltip>);
};