import * as React from "react";
import useViewportScale from "../../utils/useViewportScale";
import CancelButton from "../buttons/CancelButton.react";

export default function SplitPane({
  left,
  right,
  leftProportion = 50,
  onHideRight
}) {
  const _leftProportion = leftProportion > 100 ? 99 : leftProportion;
  const _rightProportion = 100 - parseFloat(_leftProportion);

  const viewportScale = useViewportScale();

  return viewportScale === "xs" ?
    <div className="w-100">
      <div
        style={{
          height: right != null ? "50%" : "100%"
        }}
      >
        {left}
      </div>
      {right &&
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: 0,
            width: "100%",
            height: "50%",
            zIndex: 1,
            overflow: "scroll",
          }}
        >
          {onHideRight != null &&
            <div style={{
              position: "fixed",
              zIndex: 1,
              right: "10px",
              top: "calc(50% + 10px)"
            }}>
              <CancelButton label="Close" onClick={onHideRight} />
            </div>
          }
          {right}
        </div>}
    </div> :
    <div
      className="w-100"
    >
      <div
        className="d-inline-block align-top"
        style={{
          width: (right ? _leftProportion + "%" : null),
          overflowX: "scroll"
        }}
      >
        {left}
      </div>
      {right &&
        <div
          className="d-inline-block align-top"
          style={{
            width: _rightProportion + "%",
            overflowX: "scroll",
          }}
        >
          {onHideRight != null &&
            <div
              style={{
                position: "absolute",
                width: _rightProportion + "%",
                padding: 0
              }}>
              <div style={{
                position: "absolute",
                zIndex: 1,
                right: "10px",
                top: "10px"
              }}>
                <CancelButton label="Close" onClick={onHideRight} />
              </div>
            </div>}
          {right}
        </div>}
    </div>;
}