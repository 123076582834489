// @flow strict-local

export const LOCAL_STORAGE_USER_TOKEN = "user_token";

export function getUserToken() {
  return localStorage.getItem(LOCAL_STORAGE_USER_TOKEN, null);
}

export function setUserToken(token) {
  if (token == null) {
    localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN);
  }
  localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, token);
}
