// @flow strict-local

import * as React from "react";
import { useState } from "react";
import TextInput from "../components/forms/TexInput.react";
import ConvertoTemperatureCard from "./ConvertoTemperatureCard.react";
import ConvertoWeightCard from "./ConvertoWeightCard.react";

export default function Converto(): React.MixedElement {
  // const [precision, setPrecision] = useState(null);
  return (
    <>
      <div>Converto</div>
      {/* <TextInput
        label="Precision"
        value={precision}
        onChange={(val) => (Number.isInteger(Number.parseInt(val)) ? setPrecision(Number.parseInt(val)) : setPrecision(null))}
      /> */}
      <ConvertoTemperatureCard saveToLocal={true} />
      <ConvertoWeightCard saveToLocal={true} />
    </>
  );
}
