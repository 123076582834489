import KeyedPairTextInput from "./KeyedPairTextInput.react";
import isNullOrEmpty from "../../utils/isNullOrEmpty";
import pushDefaultIfLastNonNull from "../../utils/pushDefaultIfLastNonNull";

export default function MultipleKeyedPairTextInput({
  rows,
  keyLabel,
  valueLabel,
  keyPlaceholder = 'key',
  valuePlaceholder = 'value',
  onChange,
}) {
  return (
    <>
      {rows && rows.map((obj, idx) => (
        <KeyedPairTextInput
          key={idx}
          keyLabel={keyLabel}
          valueLabel={valueLabel}
          keyPlaceholder={keyPlaceholder}
          valuePlaceholder={valuePlaceholder}
          defaultKey={obj.key}
          defaultValue={obj.value}
          onChange={value => {
            let newRows = [...rows];
            if (isNullOrEmpty(value.key) && isNullOrEmpty(value.value)) {
              newRows.splice(idx, 1);
            } else {
              newRows[idx] = {key: value.key, value: value.value};
            }
            onChange && onChange(pushDefaultIfLastNonNull(newRows, {key: '', value: ''}));
          }}
        />))}
    </>);
}