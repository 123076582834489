export default function CardHeader({className, left, right}) {
  return (
    <div className={className} style={{display: "flow-root"}} >
      <div
        style={{float: "left"}}
      >{left}</div>
      <div
        style={{float: "right"}}
      >{right}</div>
    </div>
  );
}