// @flow strict-local

import * as React from "react";
import { useState } from "react";
import ConvertoGroupCard from "./ConvertoGroupCard.react";

export default function ConvertoGroupTemperatureCard({
  saveToLocal = false,
  storagePrefix = "CONVERTO",
}): React.MixedElement {
  const LOCAL_STORAGE_CELCIUS = storagePrefix + "_CELCIUS";
  const LOCAL_STORAGE_FAHRENHEIT = storagePrefix + "_FAHRENHEIT";
  const [celcius, onChangeCelcius] = useState(
    localStorage.getItem(LOCAL_STORAGE_CELCIUS) || ""
  );
  const [fahrenheit, onChangeFahrenheit] = useState(
    localStorage.getItem(LOCAL_STORAGE_FAHRENHEIT) || ""
  );

  const setLocalStorage = (obj) => {
    const pairs = [
      [obj.f, LOCAL_STORAGE_FAHRENHEIT],
      [obj.c, LOCAL_STORAGE_CELCIUS],
    ];
    pairs.map(([val, key]) => {
      localStorage.setItem(key, val);
    });
  };

  const onChangeAny = (obj) => {
    const pairs = [
      [obj.c, onChangeCelcius],
      [obj.f, onChangeFahrenheit],
    ];
    let hasUndefined = false;
    pairs.map(([val, onChange]) => {
      if (val !== undefined) {
        onChange(val);
      } else {
        hasUndefined = true;
      }
    });

    if (hasUndefined) {
      pairs.map(([val, onChange]) => {
        onChange("");
      });
    } else if (saveToLocal) {
      setLocalStorage(obj);
    }
  };

  return (
    <ConvertoGroupCard
      title="Temperature"
      units={[
        {
          label: "Celcius",
          value: celcius,
          onChange: (val) => {
            onChangeAny({
              c: val,
              f: (val * 9) / 5 + 32,
            });
          },
        },
        {
          label: "Fahrenheit",
          value: fahrenheit,
          onChange: (val) => {
            onChangeAny({
              c: ((val - 32) * 5) / 9,
              f: val,
            });
          },
        },
      ]}
    />
  );
}
