// @flow strict-local

import * as React from "react";

export default function MikeHome(): React.MixedElement {
  return (
    <div className="App">
      <header className="App-header">
        <p>Word In Progress!</p>
      </header>
    </div>
  );
}