// @flow strict-local

import {useState, useEffect} from "react";
import * as React from "react";
import CardWrapper from "../../../components/layouts/CardWrapper.react";
import Text from "../../../components/content/Text.react";
import VATROCFetch from "../../utils/VATROCFetch";
import VATROCRosterTable from "./VATROCRosterTable.react";
import VATROCWhoCard from "../../user/VATROCWhoCard.react";
import Flexbox from "../../../components/layouts/Flexbox.react";

function FlexboxItem({children}) {
  return <span style={{minWidth: "345px", maxWidth: "345px"}}>{children}</span>;
}

export default function VATROCMCATCManager({
  setToolName,
}): React.MixedElement {
  useEffect(() => {
    setToolName("Mission Control");
  });
  const [isListLoading, setIsListLoading] = useState(true);
  const [list, setList] = useState({applications: [], atc: []});
  useEffect(() => {
    setIsListLoading(true);
    VATROCFetch(`roster`).then(
      (data) => {
        setList(data.atc);
        setIsListLoading(false);
      }
    );
  }, []);

  const [selection, setSelection] = useState([]);


  return (
    <>
      <CardWrapper>
        <Flexbox direction="row" style={{scroll: "auto"}}>
          {selection.map(id => (
            <FlexboxItem key={id}>
              <VATROCWhoCard uid={list[id].uid} onHide={() => {
                setSelection(selection.filter(row_uid => row_uid !== id));
              }} />
            </FlexboxItem>))}

        </Flexbox>
      </CardWrapper>
      <CardWrapper title="ATC">
        <React.Suspense fallback={<Text>Loading</Text>}>
          <VATROCRosterTable
            list={list}
            isListLoading={isListLoading}
            activeEntry={selection}
            onRowActivation={(id) => {
              if (selection.includes(id)) {
                setSelection(selection.filter(row_id => row_id !== id));
              } else {
                setSelection(selection.concat([id]));
              }
            }}
          />
        </React.Suspense>
      </CardWrapper>
    </>
  );
}
