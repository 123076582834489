import { Form } from "react-bootstrap";
import ClipboardButton from "../ClipboardButton.reac";

function InnerTextInput({
  label,
  onChange,
  value,
  placeholder,
  isInlineLabel = false,
  canCopy = false,
  selectOnFocus = false,
}) {
  const labelStyle = {
    whiteSpace: isInlineLabel ? "nowrap" : "auto",
    display: isInlineLabel ? "flex" : "auto",
  };
  const inputStyle = { display: isInlineLabel ? "inline-block" : "auto" };
  const inputClass = isInlineLabel ? "ms-3" : "";

  return (
    <Form.Label className="mb-3 mx-1 w-100" style={labelStyle}>
      {label}
      <Form.Control
        type="text"
        className={inputClass}
        value={value}
        onChange={(event) => onChange && onChange(event.target.value)}
        onClick={selectOnFocus ? (event)=>{
          event.target.select();
        } : null}
        placeholder={placeholder}
        style={inputStyle}
      />
      {canCopy && <ClipboardButton className="ms-3" text={value} /> }
    </Form.Label>
  );
}

export default function TextInput({
  label,
  onChange,
  isGrouped = true,
  isInlineLabel = false,
  value,
  placeholder,
  canCopy = false,
  selectOnFocus = false,
}) {
  return isGrouped ? (
    <Form.Group>
      <InnerTextInput
        label={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        isInlineLabel={isInlineLabel}
        canCopy={canCopy}
        selectOnFocus={selectOnFocus}
      />
    </Form.Group>
  ) : (
    <InnerTextInput
      label={label}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isInlineLabel={isInlineLabel}
      canCopy={canCopy}
      selectOnFocus={selectOnFocus}
    />
  );
}
