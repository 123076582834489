import {useState} from "react";
import CardHeader from "../components/layouts/CardHeader.react";
import FunctionButton from "../components/buttons/FunctionButton.react";
import ClipboardTextLink from "../components/ClipboardTextLink.react";
import KeyValueListView from "../components/layouts/KeyValueListView.react";
import pushDefaultIfLastNonNull from "../utils/pushDefaultIfLastNonNull";
import objectToKeyValueState from "../utils/objectToKeyValueState";
import {COOKIE, KEYEDVALUES} from "./Postman.react";

export default function PostmanHistoryEntry({query, onChangeForm, onChangeHeaders, onChangeCookies, onChangeBody}) {
  const [isShowMore, setShowMore] = useState(false);
  return <>
    <CardHeader
      left={<ClipboardTextLink text={query.url} />}
      right={<>
        <FunctionButton label="Show More" onClick={() => {setShowMore(!isShowMore);}} />
        <FunctionButton label="Apply" onClick={() => {
          onChangeForm({...query});
          const _headers = pushDefaultIfLastNonNull(objectToKeyValueState(query.headers, [COOKIE]), {key: '', value: ''});
          onChangeHeaders([]);
          onChangeHeaders([..._headers]);
          const _cookies = pushDefaultIfLastNonNull(objectToKeyValueState(query.cookies), {key: '', value: ''});
          onChangeCookies([..._cookies]);
          const _body = pushDefaultIfLastNonNull(objectToKeyValueState(query.body), {key: '', value: ''});
          onChangeBody([..._body]);
        }} />
      </>} />
    {isShowMore && <KeyValueListView
      className="mx-4 my-0"
      keyedObject={query}
      objectKeys={KEYEDVALUES}
      excludeList={["url"]}
    />}
  </>;
}