// @flow strict-local

import * as React from "react";
import { Suspense } from "react";
import VATROCUserState from "../user/VATROCUserState.react";
import { ErrorBoundary } from "../../components/ErrorBoundary.react";
import CardWrapper from "../../components/layouts/CardWrapper.react";

export default function VATROCLoggedOut(): React.MixedElement {
  return (
    <>
      <div
        style={{
          background:
            "linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)),url(https://www.vatroc.net/wp-content/uploads/2021/01/radar.jpg)",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="p-3"
      >
        <div
          style={{
            backgroundColor: "white",
            height: "300px",
            width: "300px",
            borderRadius: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <ErrorBoundary fallback={<div>Something went wrong</div>}>
              <Suspense fallback={<div>Loading...</div>}>
                <VATROCUserState alignCenter={true} />
              </Suspense>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </>
  );
}
