import {Form} from "react-bootstrap";
import TextInput from "./TexInput.react";
import FunctionButton from "./../buttons/FunctionButton.react";
import isNullOrEmpty from "../../utils/isNullOrEmpty";

export default function KeyedPairTextInput({
  defaultKey = '',
  defaultValue = '',
  keyLabel,
  valueLabel,
  keyPlaceholder = 'key',
  valuePlaceholder = 'value',
  onChange,
  hasClear = true,
}) {
  return (
    <Form.Group
      style={{whiteSpace: "nowrap", display: "flex"}}
    >
      <TextInput
        label={keyLabel}
        onChange={newKey => {
          onChange({key: newKey, value: defaultValue});
        }}
        isGrouped={false}
        placeholder={keyPlaceholder}
        value={defaultKey}
      />
      <TextInput
        label={valueLabel}
        onChange={newValue => {
          onChange({key: defaultKey, value: newValue});;
        }}
        isGrouped={false}
        placeholder={valuePlaceholder}
        value={defaultValue}
      />
      {hasClear && (!isNullOrEmpty(defaultKey) || !isNullOrEmpty(defaultValue)) && (
        <FunctionButton
          size="sm"
          label="X"
          onClick={() => {
            onChange({key: '', value: ''});
          }} />)}
    </Form.Group>);
}