// @flow strict-local

import {useState, useEffect} from "react";
import * as React from "react";
import CardWrapper from "../../../components/layouts/CardWrapper.react";
import Text from "../../../components/content/Text.react";
import VATROCFetch from "../../utils/VATROCFetch";
import VATROCMCApplicationsCard from "./VATROCMCApplicationsCard.react";

export default function VATROCMCApplicationManager({
  setToolName,
}): React.MixedElement {
  useEffect(() => {
    setToolName("Application Manager");
  });
  const [isListLoading, setIsListLoading] = useState(true);
  const [list, setList] = useState({applications: [], atc: []});
  useEffect(() => {
    setIsListLoading(true);
    VATROCFetch(`roster`).then(
      (data) => {
        setList(data);
        setIsListLoading(false);
      }
    );
  }, []);

  return (
    <>
      {/* <CardWrapper>
        <FunctionButton label={showAll ? "取消顯示全部" : "顯示全部"} onClick={() => {setShowAll(!showAll);}} />
      </CardWrapper> */}
      <CardWrapper title="Applications">
        <React.Suspense fallback={<Text>Loading</Text>}>
          <VATROCMCApplicationsCard
            paramKey="application"
            list={list.applications}
            isListLoading={isListLoading}
          />
        </React.Suspense>
      </CardWrapper>
      <CardWrapper title="Shortlist">
        <React.Suspense fallback={<Text>Loading</Text>}>
          <VATROCMCApplicationsCard
            paramKey="shortlist"
            list={list.shortlist}
            isListLoading={isListLoading}
          />
        </React.Suspense>
      </CardWrapper>
      <CardWrapper title="Closed">
        <React.Suspense fallback={<Text>Loading</Text>}>
          <VATROCMCApplicationsCard
            paramKey="closed"
            list={list.closed_applications}
            isListLoading={isListLoading}
          />
        </React.Suspense>
      </CardWrapper>
      <CardWrapper title="Archive">
        <React.Suspense fallback={<Text>Loading</Text>}>
          <VATROCMCApplicationsCard
            paramKey="archive"
            list={list.archive}
            isListLoading={isListLoading}
          />
        </React.Suspense>
      </CardWrapper>
    </>
  );
}
