import {Form} from "react-bootstrap";

export default function DropdownSelect({label, value, onChange, children}) {
  return (
    <Form.Group>
      <Form.Label>
        {label}
        <Form.Select onChange={event => onChange(event.target.value)} value={value}>
          {children}
        </Form.Select>
      </Form.Label>
    </Form.Group>);
}