// @flow strict-local

import * as React from "react";
import { useState } from "react";
import CardWrapper from "../components/layouts/CardWrapper.react";
import TextInput from "../components/forms/TexInput.react";

export default function ConvertoGroupCard({ title, units }): React.MixedElement {
  return (
    <CardWrapper title={title}>
      {units.map((element) => (
        <div key={element.label} >
          <TextInput
            label={element.label}
            value={element.value}
            onChange={element.onChange}
            isInlineLabel={true}
            canCopy={true}
            selectOnFocus={true}
          />
        </div>
      ))}
    </CardWrapper>
  );
}
