import * as React from "react";
import {useState} from "react";
import isNullOrEmpty from "../utils/isNullOrEmpty";
import CardWrapper from "../components/layouts/CardWrapper.react";
import TextInput from "../components/forms/TexInput.react";
import SplitPane from "../components/layouts/SplitPane.react";
import CardHeader from "../components/layouts/CardHeader.react";
import CancelButton from "../components/buttons/CancelButton.react";
import ClipboardTextLink from "../components/ClipboardTextLink.react";
import PostmanHistoryEntry from "./PostmanHistoryEntry.react";
import PostmanRequestForm from "./PostmanRequestForm.react";
import buildCookiesString from "./utils/buildCookiesString";
import FunctionButton from "../components/buttons/FunctionButton.react";
import ListGroup from "../components/lists/ListGroup.react";
import ListGroupItem from "../components/lists/ListGroupItem.react";
import TextDisplay from "../components/content/TextDisplay.react";

export const POSTMAN_HISTORY = 'POSTMAN_HISTORY';
export const POSTMAN_PAUSE_HISTORY = 'POSTMAN_PAUSE_HISTORY';
export const COOKIE = 'XCookie';
export const KEYEDVALUES = ["body", "headers", "cookies"];
const defaultUrl = "https://www.vatroc.net";
const defaultMethod = "GET";


export default function Postman() {
  const [form, setForm] = useState({
    url: defaultUrl,
    method: defaultMethod,
  });
  const [headers, setHeaders] = useState([{key: '', value: ''}]);
  const [cookies, setCookies] = useState([{key: '', value: ''}]);
  const [body, setBody] = useState([{key: '', value: ''}]);
  const [response, setResponse] = useState(null);
  const [responseText, setResponseText] = useState("");
  const historyFromLocalStorage = JSON.parse(localStorage.getItem(POSTMAN_HISTORY) || "[]");
  const [history, setHistory] = useState(historyFromLocalStorage);
  const [isHistoryPaused, setPauseHistory] = useState(localStorage.getItem(POSTMAN_PAUSE_HISTORY) || false);


  const cookieString = buildCookiesString(cookies);

  return (
    <div>
      <CardWrapper title="URL">
        <TextInput
          value={form.url}
          onChange={val => setForm({...form, url: val})}
        />
      </CardWrapper>
      <SplitPane
        leftProportion={response != null ? 50 : 100}
        left={
          <PostmanRequestForm
            headers={headers}
            setHeaders={setHeaders}
            cookies={cookies}
            setCookies={setCookies}
            body={body}
            setBody={setBody}
            setResponse={setResponse}
            setResponseText={setResponseText}
            form={form}
            setForm={setForm}
            history={history}
            setHistory={setHistory}
            isHistoryPaused={isHistoryPaused}
          />
        }
        right={response && <CardWrapper
          title={<CardHeader
            left="Response"
            right={
              <CancelButton
              label="Clear"
              onClick={() => {
                setResponse(null);
                setResponseText("");
              }}
              />
            }
          />} >
          <ListGroup>
            <ListGroupItem
              left="Status Code"
              right={<p>{response.status}</p>}
            />
            <ListGroupItem
              left="Status Text"
              right={<p>{response.statusText}</p>}
            />
            <ListGroupItem
              left="Headers"
              right={
                <ListGroup>
                  {[...response.headers].map(vec => (<ListGroupItem left={vec[0]} right={vec[1]} />))}
                </ListGroup>}
            />
            <ListGroupItem
              style={{display: "contents"}}
              left="Body"
              right={<TextDisplay text={responseText} />}
            />
          </ListGroup>
        </CardWrapper>
        }
      />

      {
        <CardWrapper
          title={<CardHeader
            left="History"
            right={
              <>
                <FunctionButton
                  label={(isHistoryPaused ? "Continue" : "Pause") + " History"}
                  onClick={
                    () => {
                      setPauseHistory(!isHistoryPaused);
                      if (isHistoryPaused) {
                        localStorage.removeItem(POSTMAN_PAUSE_HISTORY);
                      } else {
                        localStorage.setItem(POSTMAN_PAUSE_HISTORY, true);
                      }
                    }
                  }
                />
              <CancelButton
                label="Clear"
                onClick={
                  () => {
                    localStorage.removeItem(POSTMAN_HISTORY);
                    setHistory([]);
                  }
                }
                />
              </>
            }
          />}>
          {[...history].reverse().map((query, idx) =>
            <PostmanHistoryEntry
              key={idx}
              query={query}
              onChangeForm={setForm}
              onChangeHeaders={setHeaders}
              onChangeCookies={setCookies}
              onChangeBody={setBody}
            />
          )}
        </CardWrapper>
      }

      <CardWrapper title="cURL">
        <ClipboardTextLink text={'curl' + (!isNullOrEmpty(cookieString) ? ' --cookie "' + cookieString + '" ' : ' ') + form.url} />
      </CardWrapper>
    </div>);
}