import * as React from "react";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import Layout from "./layout/Layout.react";
import Home from "./layout/Home.react";
import Postman from "./postman/Postman.react";
import SingleSignOn from "./single_sign_on/SingleSignOn.react";
import VATROCRouter from "./vatroc/VATROCRouter.react";
import MikeRouter from "./mike_home/MikeRouter.react";
import Converto from "./converto/Converto.react";

export default function Router() {
  if (process.env.REACT_APP_DOMAIN === 'vatroc') {
    return (
      <BrowserRouter>
        <VATROCRouter />
      </BrowserRouter>
    );
  } else if (process.env.REACT_APP_DOMAIN === 'mikehome') {
    return (
      <BrowserRouter>
        <MikeRouter />
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="converto" element={<Converto />} />
          <Route path="postman" element={<Postman />} />
          <Route path="sso" element={<SingleSignOn />} />
          <Route path="vatroc/*" element={<VATROCRouter />} />
          <Route path="mike/*" element={<MikeRouter />} />
        </Route>
      </Routes>
    </BrowserRouter>);
}

