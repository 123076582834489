// @flow strict-local

import {useSearchParams} from "react-router-dom";
import * as React from "react";
import SplitPane from "../../components/layouts/SplitPane.react";
import useSearchParamsIDToActiveEntry from "../utils/useSearchParamsIDToActiveEntry";
import VATROCRosterTable from "./atc/VATROCRosterTable.react";
import VATROCWhoCard from "../user/VATROCWhoCard.react";

export default function VATROCMCRosterCard({list, isListLoading}): React.MixedElement {
  const paramKey = "roster";
  const [searchParams, setSearchParams] = useSearchParams();
  const {activeEntry} = useSearchParamsIDToActiveEntry(list, paramKey);

  return (
    <SplitPane
      left={
        <div className="mx-3">
          <VATROCRosterTable
            list={list}
            isListLoading={isListLoading}
            activeEntry={activeEntry}
            onRowActivation={(id) => {
              // setActiveEntry(activeEntry === id ? null : id);
              const uid = list[id].uid;
              if (searchParams.get(paramKey) === uid) {
                searchParams.delete(paramKey);
                setSearchParams(searchParams);
              } else {
                searchParams.set(paramKey, uid);
                setSearchParams(searchParams);
              }
            }}
          />
        </div>
      }
      right={
        activeEntry != null && (
          <div className="mx-3 h-100">
            <VATROCWhoCard
              uid={searchParams.get(paramKey)}
              onHide={() => {
                searchParams.delete(paramKey);
                setSearchParams(searchParams);
              }}
            />
          </div>
        )
      }
    />
  );
}
