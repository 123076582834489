// @flow strict-local

import {AlphaGroupContext} from "../../utils/alphaGroupContext";
import {useContext, useEffect, useState} from "react";
import * as React from "react";
import CancelButton from "../../../components/buttons/CancelButton.react";
import CardHeader from "../../../components/layouts/CardHeader.react";
import CardWrapper from "../../../components/layouts/CardWrapper.react";
import DateText from "../../../components/content/DateText.react";
import Flexbox from "../../../components/layouts/Flexbox.react";
import FunctionButton from "../../../components/buttons/FunctionButton.react";
import ListGroup from "../../../components/lists/ListGroup.react";
import ListGroupItem from "../../../components/lists/ListGroupItem.react";
import TextInput from "../../../components/forms/TexInput.react";
import UserRoundProfile from "../../../components/user/UserRoundProfile.react";
import VATROCFetch from "../../utils/VATROCFetch";

function ArchiveButton({uuid, disabled, setDisabled}) {
  return (
    <FunctionButton
      label="封存"
      disabled={disabled}
      onClick={async () => {
        setDisabled && setDisabled();
        await VATROCFetch(
          `application_submissions/${uuid}/status/0`,
          {method: "POST"}
        ).then(() => {
          window.location.reload();
        });
      }}
    />
  );
}

function UnarchiveButton({uuid, disabled, setDisabled}) {
  return (
    <FunctionButton
      disabled={disabled}
      label="取消封存"
      onClick={async () => {
        setDisabled && setDisabled();
        await VATROCFetch(
          `application_submissions/${uuid}/status/1`,
          {method: "POST"}
        );
        window.location.reload();
      }}
    />
  );
}

function MarkInProgressButton({uuid, disabled, setDisabled}) {
  return (
    <FunctionButton
      disabled={disabled}
      label="啟動流程"
      onClick={async () => {
        setDisabled && setDisabled();
        await VATROCFetch(
          `application_submissions/${uuid}/status/2`,
          {method: "POST"}
        );
        window.location.reload();
      }}
      variant="outline-primary"
    />
  );
}

function MarkCloseSubmissionButton({uuid, disabled, setDisabled}) {
  return (
    <FunctionButton
      disabled={disabled}
      label="丟下去（開始OJT）"
      onClick={async () => {
        setDisabled && setDisabled();
        await VATROCFetch(
          `application_submissions/${uuid}/status/3`,
          {method: "POST"}
        );
        window.location.reload();
      }}
      variant="outline-primary"
    />
  );
}

function SubmitCommentButton({uuid, disabled, setDisabled, comment, onComplete}) {
  return (
    <FunctionButton
      disabled={disabled}
      label="送出留言"
      onClick={async () => {
        if (comment == null || comment.length === 0) {
          return;
        }
        setDisabled && setDisabled(true);
        const data = {comment: comment};
        const formData = new FormData();
        formData.append("comment", comment);
        await VATROCFetch(
          `application_submissions/${uuid}/comment`,
          {
            method: "POST",
            // headers: {"Content-Type": "multipart/form-data"},
            headers: {"Content-Type": "application/json"},
            // mode: "cors",
            // body: formData
            body: JSON.stringify(data)
          }
        );
        setDisabled && setDisabled(false);
        onComplete && onComplete();
      }}
      variant="primary"
    />
  );
}

export default function VATROCMCApplicationDetailCard({
  item,
  onHide,
}): React.MixedElement {

  const [isRequestTriggered, setIsRequestTriggered] = useState(false);
  const [commentText, setCommentText] = useState("");


  const {isAlpha} = useContext(AlphaGroupContext);

  const [comments, setComments] = useState([]);
  useEffect(() => {
    VATROCFetch(
      `application_submissions/${item.uuid}/comments`
    ).then((comments) => {
      setComments(comments);
    });
  }, [item.uuid, setComments]);
  if (!item) {
    return <></>;
  }
  return (
    <CardWrapper className="h-100">
      <CardHeader className="mb-2" right={<CancelButton label="Close" onClick={onHide} />} />
      <ListGroup className="mb-3">
        <ListGroupItem
          left={<UserRoundProfile uid={item.uid} src={item.user_profile_src} />}
          right={
            <Flexbox>
              <span>{item.eng_name}</span>
              <span>{item.ch_name}</span>
              <span>{item.visiting === "true" ? "Visiting" : null}</span>
            </Flexbox>
          }
        />
        <ListGroupItem
          left={<>Time</>}
          right={<DateText unix={item.timestamp} dateOnly={true}/>}
        />
        <ListGroupItem left={<>VATSIM UID</>} right={<>{item.vatsimid}</>} />
        <ListGroupItem left={<>Birth Year</>} right={<>{item.birth_year}</>} />
        <ListGroupItem left={<>Email</>} right={<>{item.Email}</>} />
        <ListGroupItem left={<>Edu</>} right={<>{item.EDUstatus}</>} />
        <ListGroupItem left={<>Job</>} right={<>{item.Occupation}</>} />
        <ListGroupItem left={<>Chinese</>} right={<>{item.CHproficiency}</>} />
        <ListGroupItem left={<>English</>} right={<>{item.ENproficiency}</>} />
        <ListGroupItem left={<>FS</>} right={<>{item["FSexperiences:"] ?? item["FSexperiences"]}</>} />
        <ListGroupItem left={<>More</>} right={<>{item.more}</>} />
        {isAlpha && <ListGroupItem left={<>UUID</>} right={<>{item.uuid}</>} />}
        <ListGroupItem
          left={
            item.status == null || parseInt(item.status) > 0 ? (
              <ArchiveButton
                disabled={isRequestTriggered}
                setDisabled={() => {setIsRequestTriggered(true);}}
                uuid={item.uuid}
              />
            ) : (
              <UnarchiveButton
                disabled={isRequestTriggered}
                setDisabled={() => {setIsRequestTriggered(true);}}
                uuid={item.uuid}
              />
            )
          }
          right={
            parseInt(item.status) === 2
              ? <MarkCloseSubmissionButton
                disabled={isRequestTriggered}
                setDisabled={() => {setIsRequestTriggered(true);}}
                uuid={item.uuid}
              />
              : <MarkInProgressButton
                disabled={isRequestTriggered}
                setDisabled={() => {setIsRequestTriggered(true);}}
                uuid={item.uuid}
              />
          }
        />
      </ListGroup>
      <ListGroup>
        {comments.map(comment => (
          <ListGroupItem left={<>{comment.content}</>} right={
            <Flexbox>
              <UserRoundProfile
                uid={comment.author}
                src={comment.user_profile_src}
                size="30px"
              />
              <DateText
                style={{color: "grey", fontSize: "10px"}}
                unix={comment.create_time}
                dateOnly={true}
              />
            </Flexbox>

          } />))}
        <ListGroupItem
          left={<TextInput onChange={setCommentText} value={commentText} />}
          right={
            <SubmitCommentButton
              disabled={commentText == null
                || commentText.length === 0
                || isRequestTriggered
              }
              setDisabled={(v) => {
                setIsRequestTriggered(v);
              }}
              uuid={item.uuid}
              comment={commentText}
              onComplete={() => {
                setCommentText("");
                comments.push({content: commentText, display_name: "you"});
                setComments(comments);
              }}
            />}
        />
      </ListGroup>
    </CardWrapper>
  );
}
