import {Button} from "react-bootstrap";

export default function FunctionButton({
  label = "Click me",
  onClick,
  className,
  size,
  disabled = false,
  variant = "outline-secondary"
}) {
  return <Button
    className={"ms-1 " + className}
    disabled={disabled}
    onClick={onClick}
    size={size}
    variant={variant}
  >{label}</Button>;
}