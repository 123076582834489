// @flow strict-local

import {FaCheckCircle} from "react-icons/fa";
import {useSearchParams} from "react-router-dom";
import * as React from "react";
import BasicTable from "../../../components/table/BasicTable.react";
import DateText from "../../../components/content/DateText.react";
import SplitPane from "../../../components/layouts/SplitPane.react";
import UserRoundProfile from "../../../components/user/UserRoundProfile.react";
import useSearchParamsIDToActiveEntry from "../../utils/useSearchParamsIDToActiveEntry";
import VATROCMCApplicationDetailCard from "./VATROCMCApplicationDetailCard.react";

function TableCell({children}) {
  return <span style={{whiteSpace: "nowrap"}}>{children}</span>;
}

export default function VATROCMCApplicationsCard({
  list,
  isListLoading,
  paramKey,
  style
}): React.MixedElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const {activeEntry} = useSearchParamsIDToActiveEntry(list, paramKey);


  const keys = [
    "uid",
    "visiting",
    "vatsimid",
    "eng_name",
    "ch_name",
    "birth_year",
    "Occupation",
    "timestamp",
  ];
  const cellSpec = {
    uid: {
      header: <TableCell></TableCell>,
      cell: (item) => (
        <UserRoundProfile uid={item.uid} src={item.user_profile_src} />
      ),
      width: null,
    },
    timestamp: {
      header: <TableCell>Time</TableCell>,
      cell: (item) => (
        <TableCell>
          <DateText unix={item.timestamp} />
        </TableCell>
      ),
      width: null,
    },
    status: {
      header: <TableCell>Status</TableCell>,
      cell: (item) => <TableCell>{item.status}</TableCell>,
      width: null,
    },
    visiting: {
      header: <TableCell>Visiting</TableCell>,
      cell: (item) => <TableCell>{item.visiting === "true" ? <FaCheckCircle /> : null}</TableCell>,
      width: null,
    },
    vatsimid: {
      header: <TableCell>VATSIM</TableCell>,
      cell: (item) => <TableCell>{item.vatsimid}</TableCell>,
      width: null,
    },
    eng_name: {
      header: <TableCell>Name</TableCell>,
      cell: (item) => <TableCell>{item.eng_name}</TableCell>,
      width: null,
    },
    ch_name: {
      header: <TableCell>Name</TableCell>,
      cell: (item) => <TableCell>{item.ch_name}</TableCell>,
      width: null,
    },
    birth_year: {
      header: <TableCell>Birth Year</TableCell>,
      cell: (item) => <TableCell>{item.birth_year}</TableCell>,
      width: null,
    },
    Email: {
      header: <TableCell>Email</TableCell>,
      cell: (item) => <TableCell>{item.Email}</TableCell>,
      width: null,
    },
    EDUstatus: {
      header: <TableCell>EDU</TableCell>,
      cell: (item) => <TableCell>{item.EDUstatus}</TableCell>,
      width: null,
    },
    Occupation: {
      header: <TableCell>Occupation</TableCell>,
      cell: (item) => <TableCell>{item.Occupation}</TableCell>,
      width: null,
    },
    CHproficiency: {
      header: <TableCell>Chinese</TableCell>,
      cell: (item) => <TableCell>{item.CHproficiency}</TableCell>,
      width: null,
    },
    ENGproficiency: {
      header: <TableCell>English</TableCell>,
      cell: (item) => <TableCell>{item.ENGproficiency}</TableCell>,
      width: null,
    },
    FSexperiences: {
      header: <TableCell>Experiences</TableCell>,
      cell: (item) => <TableCell>{item.FSexperiences}</TableCell>,
      width: null,
    },
    more: {
      header: <TableCell>More</TableCell>,
      cell: (item) => <TableCell>{item.more}</TableCell>,
      width: null,
    },
  };
  return (
    <div style={style}>
      <SplitPane
        left={
          <div
            className="mx-3"
            style={{maxHeight: "100%"}}
          >
            {isListLoading ? <div>"Loading..."</div> : <BasicTable
              index={true}
              keys={keys}
              spec={cellSpec}
              data={list}
              onRowActivation={(id) => {
                const uid = list[id].uid;
                if (searchParams.get(paramKey) === uid) {
                  searchParams.delete(paramKey);
                  setSearchParams(searchParams);
                } else {
                  searchParams.set(paramKey, uid);
                  setSearchParams(searchParams);
                }
              }}
              active={activeEntry}
            />}
          </div>
        }
        right={
          activeEntry != null && (
            <div className="mx-3 h-100">
              <VATROCMCApplicationDetailCard
                item={list[activeEntry]}
                onHide={() => {
                  const obj = searchParams;
                  obj.delete(paramKey);
                  setSearchParams(obj);
                }}
              />
            </div>
          )
        }
      />
    </div>
  );
}
