// @flow strict-local

import * as React from "react";
import {AlphaGroupContext, isUserAlphpaGroup} from "./utils/alphaGroupContext";
import {CurrentUserContext} from "./utils/currentUserContext";
import {Routes, Route} from "react-router-dom";
import {Suspense, useState} from "react";
import VATROC from "../vatroc/VATROC.react";
import VATROCLayout from "../vatroc/VATROCLayout.react";
import VATROCLoggedOut from "./single_sign_on/VATROCLoggedOut.react";
import VATROCMCApplicationManager from "./mission_control/application/VATROCMCApplicationManager.react";
import VATROCMCRosterCard from "./mission_control/VATROCMCRosterCard.react";
import VATROCMissionControl from "./mission_control/VATROCMissionControl.react";
import VATROCSingleSignOn from "./single_sign_on/VATROCSingleSignOn.react";
import VATROCWho from "./user/VATROCWho";
import VATROCMCATCManager from "./mission_control/atc/VATROCMCATCManager.react";

export default function Router(): React.MixedElement {
  const [toolName, setToolName] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserToken, setCurrentUserToken] = useState(null);
  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        currentUserToken,
        setCurrentUserToken,
      }}
    >
      <AlphaGroupContext.Provider value={{
        isAlpha: currentUser != null && currentUser.user != null ? isUserAlphpaGroup(currentUser.user.ID) : false
      }}>
        <Routes>
          <Route
            path="sso"
            element={
              <VATROCSingleSignOn
                next={
                  process.env.REACT_APP_DOMAIN === "vatroc" ? "/" : "/vatroc/"
                }
              />
            }
          />
          <Route path="login" element={<VATROCLoggedOut />} />
          <Route
            path="/"
            element={
              <Suspense fallback={<>loading</>}>
                <VATROCLayout toolName={toolName} />
              </Suspense>
            }
          >
            <Route index element={<VATROCMissionControl setToolName={setToolName} />} />
            <Route
              path="who/:uid"
              element={<VATROCWho setToolName={setToolName} />}
            />
            <Route path="application_manager" element={<VATROCMCApplicationManager setToolName={setToolName} />} />
            <Route path="atc_manager" element={<VATROCMCATCManager setToolName={setToolName} />} />
            <Route path="list" element={<VATROC />} />
            <Route path="roster" element={<VATROCMCRosterCard />} />
          </Route>
        </Routes>
      </AlphaGroupContext.Provider>
    </CurrentUserContext.Provider>
  );
}
