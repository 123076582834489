// @flow strict-local

import {CurrentUserContext} from "../utils/currentUserContext";
import {useContext} from "react";
import * as React from "react";
import UserRoundProfile from "../../components/user/UserRoundProfile.react";
import VATROCLoginButton from "./VATROCLoginButton.react";
import VATROCLogoutButton from "./VATROCLogoutButton.react";

export default function VATROCUserState({
  alignCenter = false,
}): React.MixedElement {
  const {currentUser} =
    useContext(CurrentUserContext);

  return (
    <div style={{textAlign: alignCenter ? "center" : null}}>
      {currentUser != null && (
        <UserRoundProfile src={currentUser.user_profile_src} />
      )}
      <span className="ms-1">{currentUser && currentUser.display_name}</span>
      {currentUser ? (
        <VATROCLogoutButton />
      ) : (
          <VATROCLoginButton />
      )}
    </div>
  );
}
