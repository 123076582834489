// @flow strict-local

import { useState } from "react";
import FunctionButton from "../buttons/FunctionButton.react";

export default function Text({ text = "", xstyle = {}, ...props }) {
    const lines = typeof text === "string" ? text.split("\n") : '';
    const style = { whiteSpace: "nowrap", overflow: "hidden", ...props.xxstyle, };

    return (
        <div className={"m-0"} style={style} {...props} >
            {text}
        </div>
    );
}