// @flow strict-local

import React from "react";

export default function DateText({unix, dateOnly = false, ...props}) {
    const date = new Date(unix * 1000);
    try {
        const formattedTime = date.toISOString();
        const dateOnlyText = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

        return (
            <p
                style={{display: "inline", margin: 0, ...props.style}}
                className={props.className}
            >
                {dateOnly ? dateOnlyText : formattedTime}
            </p>
        );
    } catch {
        return (<>{unix}</>);
    }

    // const hours = date.getHours();
    // const minutes = "0" + date.getMinutes();
    // const seconds = "0" + date.getSeconds();
    // const hasNaN = [hours, minutes, seconds].includes(NaN);
    // const formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

}