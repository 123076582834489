import CardWrapper from "../components/layouts/CardWrapper.react";
import DropdownSelect from "../components/forms/DropdownSelect.react";
import MultipleKeyedPairTextInput from "../components/forms/MultipleKeyedPairTextInput.react";
import isNullOrEmpty from "../utils/isNullOrEmpty";
import pushDefaultIfLastNonNull from "../utils/pushDefaultIfLastNonNull";
import keyValueStateToObject from "../utils/keyValueStateToObject";
import buildCookiesString from "./utils/buildCookiesString";
import {COOKIE, POSTMAN_HISTORY} from "./Postman.react";
import SubmitButton from "../components/buttons/SubmitButton.react";


const CorsProxy = "https://no-more-cors.thechaos.tw/";

function mergeHeadersCookie(headers, cookies) {
  const _cookies = cookies.filter(obj => !isNullOrEmpty(obj.key));
  const _headers = [...headers];
  _headers.filter(obj => obj.key && obj.key.toLocaleLowerCase() === "cookie")
    .map(obj => obj.value &&
      obj.value.split(";")
        .map((cookie) => {
          const vecCookie = cookie.split("=");
          _cookies.push({key: vecCookie[0], value: vecCookie[1]});
          return null;
        })
    );
  return pushDefaultIfLastNonNull(_cookies, {key: '', value: ''});
}

function clearCookieFromHeaders(headers) {
  const _headers = [...headers];
  return pushDefaultIfLastNonNull(
    _headers.filter(obj => obj.key && obj.key.toLocaleLowerCase() !== "cookie"),
    {key: '', value: ''}
  );
}

export default function PostmanRequestForm({
  headers,
  setHeaders,
  cookies,
  setCookies,
  body,
  setBody,
  setResponse,
  setResponseText,
  form,
  setForm,
  history,
  setHistory,
  isHistoryPaused = false,
}) {

  const setupUrlParams = (_body) => {
    if (Object.keys(_body).length === 0) {return;}
    const urlParams = new URLSearchParams(_body).toString();
    let url = form.url;
    if (!url.includes("?")) {
      if (url.slice(-1) !== "/") {
        url += "/";
      }
      url += "?";
    } else {
      url += "&";
    }
    url += urlParams;
    setForm({...form, url: url});
    setBody([{key: '', value: ''}]);
    return url;
  }

  const setupStatesForFetch = () => {
    const newCookies = mergeHeadersCookie(headers, cookies);
    setCookies(newCookies);
    setHeaders(clearCookieFromHeaders(headers));

    const _headers = keyValueStateToObject(headers);
    const cookieString = buildCookiesString(cookies);
    if (!isNullOrEmpty(cookieString)) {
      _headers[COOKIE] = cookieString;
    }

    const _body = keyValueStateToObject(body);
    if (!isHistoryPaused) {
      history.push({
        ...form,
        headers: Object.fromEntries(Object.entries(_headers).filter(([key]) => !key.includes(COOKIE))),
        body: _body,
        cookies: keyValueStateToObject(cookies)
      });
      setHistory([...history]);
      localStorage.setItem(POSTMAN_HISTORY, JSON.stringify(history));
    }
    return {_headers, _body};
  };

  const myFetch = async () => {
    const {_headers, _body} = setupStatesForFetch();
    const metaObj = {
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: _headers, // 'Content-Type': 'application/x-www-form-urlencoded',
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    metaObj["method"] = form.method;
    let url = form.url;
    if (form.method === "GET") {
      url = setupUrlParams(_body);
    } else {
      metaObj["body"] = JSON.stringify(_body); // body data type must match "Content-Type" header
      Object.keys(_headers).map(key => {
        if (key.toLowerCase() === "content-type") {
            if (_headers[key].toLowerCase() === "application/x-www-form-urlencoded") {
              metaObj["body"] = new URLSearchParams(_body).toString();
            }
          }
      });
    }
    return await fetch(CorsProxy + url, metaObj);
  };

  return (
    <CardWrapper
      title="Request"
    >
      <DropdownSelect
        label="Method"
        value={form.method}
        onChange={value => setForm({...form, method: value})}
      >
        <option>GET</option>
        <option>POST</option>
        <option>PUT</option>
        <option>DELETE</option>
        <option>OPTIONS</option>
      </DropdownSelect>
      <div>
        Headers
        <MultipleKeyedPairTextInput
          rows={headers}
          onChange={data => setHeaders(data)}
        />
      </div>
      <div>
        Cookies
        <MultipleKeyedPairTextInput
          rows={cookies}
          onChange={data => setCookies(data)}
        />
      </div>
      <div>
        Body
        <MultipleKeyedPairTextInput
          rows={body}
          onChange={data => setBody(data)}
        />
      </div>

      <p><SubmitButton label="Query" onClick={async event => {
        const response = await myFetch();
        setResponse && setResponse(response);
        setResponseText && setResponseText(await response.text());
      }
      }
      /></p>
    </CardWrapper>
  );
}