// @flow strict-local

import {AlphaGroupContext} from "../utils/alphaGroupContext";
import {FaExternalLinkAlt} from "react-icons/fa";
import {useState, useEffect, useContext} from "react";
import * as React from "react";
import Calendr from "../../components/calendar/Calendar.react";
import CardWrapper from "../../components/layouts/CardWrapper.react";
import LinkButton from "../../components/buttons/LinkButton.react";
import Text from "../../components/content/Text.react";
import VATROCFetch from "../utils/VATROCFetch";
import VATROCMCApplicationsCard from "./application/VATROCMCApplicationsCard.react";
import VATROCMCRosterCard from "./VATROCMCRosterCard.react";

export default function VATROCMissionControl({
  setToolName,
}): React.MixedElement {
  useEffect(() => {
    setToolName("Mission Control");
  });
  const [isListLoading, setIsListLoading] = useState(true);
  const [list, setList] = useState({applications: [], atc: []});
  useEffect(() => {
    setIsListLoading(true);
    VATROCFetch(`roster`).then(
      (data) => {
        setList(data);
        setIsListLoading(false);
      }
    );
  }, []);

  const {isAlpha} = useContext(AlphaGroupContext);

  return (
    <>
      {/* <CardWrapper title="Today">
        {Date.now()}
      </CardWrapper>
      <CardWrapper title="Avail">
        Render avail here.
      </CardWrapper>
      <CardWrapper title="Trainee">
        |-------------------------------------------------------------------------------------------------------|
        | who | date since OJT | events since OJT (total) | A/C average | future attendance | last 5 attendance |
        |-------------------------------------------------------------------------------------------------------|
        <Calendr />
      </CardWrapper> */}
      <CardWrapper
        title="Applications"
        right={
          <LinkButton
            label={<>顯示全部 <FaExternalLinkAlt /></>}
            href="/application_manager"
            target="_blank"
          />
        }
      >
        <React.Suspense fallback={<Text>Loading</Text>}>
          <VATROCMCApplicationsCard
            paramKey="application"
            list={list.applications}
            isListLoading={isListLoading}
          />
        </React.Suspense>
      </CardWrapper>
      <CardWrapper title="Shortlist">
        <React.Suspense fallback={<Text>Loading</Text>}>
          <VATROCMCApplicationsCard
            paramKey="shortlist"
            list={list.shortlist}
            isListLoading={isListLoading}
          />
        </React.Suspense>
      </CardWrapper>
      <CardWrapper
        title="ATC"
        right={<LinkButton label={<FaExternalLinkAlt />} href="/atc_manager" hideBorder={true} target="_blank" />}
      >
        <React.Suspense fallback={<Text>Loading</Text>}>
          <VATROCMCRosterCard
            list={list.atc}
            isListLoading={isListLoading}
          />
        </React.Suspense>
      </CardWrapper>
    </>
  );
}
