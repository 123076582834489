// @flow strict-local

import LinkButton from "../../components/buttons/LinkButton.react";

export default function VATROCLoginButton({
}): React.MixedElement {
  const redirect_uri = new URL("sso", window.location.href);
  const loginEndpoint =
    "https://www.vatroc.net/wp-json/jwt-auth/v1/token?redirect_uri=" +
    redirect_uri.toString();
  return (
    <LinkButton label="Login with VATROC" href={loginEndpoint} />
  );
}
