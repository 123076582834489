import {useEffect, useState} from "react";
import useWindowDimensions from "./useWindowDimensions";

export default function useViewportScale() {
  const [viewportScale, setViewportScale] = useState(null);
  const {height, width} = useWindowDimensions();

  useEffect(() => {
    if (width < 576) {
      setViewportScale("xs");
    } else if (width < 768) {
      setViewportScale("sm");
    } else if (width < 992) {
      setViewportScale("md");
    } else if (width < 1200) {
      setViewportScale("lg");
    } else if (width < 1400) {
      setViewportScale("xl");
    } else {
      setViewportScale("xxl");
    }
  }, [height, width, setViewportScale]);
  return viewportScale;
}