import {useState} from "react";
import FunctionButton from "../buttons/FunctionButton.react";

export default function TextDisplay({text = "", numberOfLines = 5}) {
  const [showFullText, setShowFullText] = useState(false);
  const lines = text.split("\n");

  return (
    <div className="m-0">
      {showFullText ? text : lines.slice(0, numberOfLines).map((line, index) => (<p className="m-0" key={index}>{line}</p>))}

      <FunctionButton
        style={{float: "right"}}
        label={showFullText ? "Show Less" : "Show More"}
        onClick={() => (setShowFullText(!showFullText))
        }
      />
    </div>
  );
}