import {ListGroup} from "react-bootstrap";

export default function ListGroupItem({children, left, right, style}) {
  return (children != null ? (
    <ListGroup.Item>
      {children}
    </ListGroup.Item>
  ) : (
    <ListGroup.Item>
        <div style={{float: "left", ...style}}>{left}</div>
        <div style={{float: "right", ...style}}>{right}</div>
    </ListGroup.Item>
  )
  );
}